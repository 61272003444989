import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import { colors, breakpoints, fonts } from '../../style-utilities/variables';
import DefaultSharingImage from './../../static/ansira-logo-sharing.png';

//components
import Arrow from '../../components/Arrow';
import ArrowLeft from '../../components/ArrowLeft';
import Hero from '../../components/Hero';
import Filters from '../../components/Filters';
import BlogGrid from '../../components/Blog/BlogGrid';
import TinyPagination from '../../components/TinyPagination';
import ContactBanner from '../../components/ContactBanner';

const StyledBlogContainer = styled.div`
  margin: 0.75em auto;
  max-width: 1500px;
  .no-posts {
    text-align: center;
  }
  .counter-container {
    display: none;
  }
`;

class BlogPosts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setSortValue: {},
      setTopicValue: {},
      setServiceValue: {},
      setVerticalValue: {},
      sortValue: '',
      topicValue: '',
      topicId: '',
      serviceValue: '',
      serviceId: '',
      verticalValue: '',
      valueId: '',
      isBrowser: typeof window !== 'undefined',
      selectedPageId: 1
    };

    this.changePage = this.changePage.bind(this);
    this.renderBtnNumber = this.renderBtnNumber.bind(this);
  }

  /**
   * METHOD: Scroll to top on pagination click needs scrollStep
   */
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }
  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }

  /**
   * METHOD: Change page on Pagination
   * @param: id of the current page
   */
  changePage(id) {
    this.setState(prevState => {
      return {
        ...prevState,
        selectedPageId: id
      };
    });
  }
  /**
   * METHOD: for button Prev and Next on Pagination
   * @param: id of the current page
   */
  buttonPageClick(id) {
    let { selectedPageId } = this.state;
    switch (id) {
      case '<':
        this.changePage(selectedPageId - 1);
        break;
      case '>':
        this.changePage(selectedPageId + 1);
        break;
      default:
        this.changePage(id);
        break;
    }
    this.scrollToTop();
  }
  /**
   * METHOD: Render button to the UI
   * @param: id of the current page
   */
  renderBtnNumber = id => {
    let { selectedPageId } = this.state;

    let buttonContent = id;
    if (id == '<') {
      buttonContent = <ArrowLeft />;
    }
    if (id == '>') {
      buttonContent = <Arrow />;
    }

    return (
      <button
        onClick={this.buttonPageClick.bind(this, id)}
        key={id}
        className={`page ${selectedPageId === id ? 'selected-page' : ''}`}
      >
        {buttonContent}
      </button>
    );
  };

  /* Get Topic Service and Vertical value from filter and store for pagination*/
  handleTopic = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        "handleTopic",
        e ? JSON.stringify(e) : window.localStorage.removeItem("handleTopic")
      );
    }
    this.setState({
      topicValue: e ? e.value : null,
      setTopicValue: e ? e : null,
      selectedPageId: 1
    });
  };
  handleService = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        "handleService",
        e ? JSON.stringify(e) : window.localStorage.removeItem("handleService")
      );
    }
    this.setState({
      serviceValue: e ? e.value : null,
      setServiceValue: e ? e : null,
      selectedPageId: 1
    });
  };
  handleVertical = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        "handleVertical",
        e ? JSON.stringify(e) : window.localStorage.removeItem("handleVertical")
      );
    }
    this.setState({
      verticalValue: e ? e.value : null,
      setVerticalValue: e ? e : null,
      selectedPageId: 1
    });
  };
  handleSort = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        "handleSort",
        e ? JSON.stringify(e) : window.localStorage.removeItem("handleSort")
      );
    }
    this.setState({
      sortValue: e ? e.value : null,
      setSortValue: e ? e : null
    });
  };

  render() {
    const props = this.props;
    const tags = props.tags;
    let topics = props.data.allWordpressWpTopic;
    let service = props.data.allWordpressWpOurSolutions;
    let vertical = props.data.allWordpressWpVertical;
    let posts = props.data.allWordpressPost.edges;
    let posts_page = props.data.wordpressPage;

    let filteredPosts = [];
    const topicsList = [];
    const servicesList = [];
    const verticalsList = [];
    let usedTopics = [];
    let usedServices = [];
    let usedVerticals = [];
    let selectedValuesArray = [];
    let queryParameter = '';
    let queryNumber = '';
    let topicPlaceholder = 'Topic';

    if (typeof document !== 'undefined') {
      queryParameter = window.location.href.split('=');
      queryNumber = parseInt(queryParameter[1]);
    }

    if (queryParameter.length > 1) {
      selectedValuesArray.push(parseInt(queryParameter[1]));
    }

    //if item in Array of selected state matches items in single post, push that post to a new posts array and send to Blog Grid
    if (this.state.topicValue != '' && this.state.topicValue != null) {
      selectedValuesArray.push(this.state.topicValue);
    }
    if (this.state.serviceValue != '' && this.state.serviceValue != null) {
      selectedValuesArray.push(this.state.serviceValue);
    }
    if (this.state.verticalValue != '' && this.state.verticalValue != null) {
      selectedValuesArray.push(this.state.verticalValue);
    }

    posts.map((post, i) => {
      let thisPostsValues = [];

      posts[i].node.topic.map((topic, j) => {
        usedTopics.push(topic);
        thisPostsValues.push(posts[i].node.topic[j]);
        if (
          posts[i].node.topic[j] == this.state.topicValue &&
          this.state.serviceValue == '' &&
          this.state.verticalValue == ''
        ) {
          filteredPosts.push(post);
        }
      });

      posts[i].node.our_solutions.map((services, k) => {
        usedServices.push(services);
        thisPostsValues.push(posts[i].node.our_solutions[k]);
        if (
          posts[i].node.our_solutions[k] == this.state.serviceValue &&
          this.state.topicValue == '' &&
          this.state.verticalValue == ''
        ) {
          filteredPosts.push(post);
        }
      });

      posts[i].node.vertical.map((vertical, l) => {
        usedVerticals.push(vertical);
        thisPostsValues.push(posts[i].node.vertical[l]);
        if (
          posts[i].node.vertical[l] == this.state.verticalValue &&
          this.state.serviceValue == '' &&
          this.state.topicValue == ''
        ) {
          filteredPosts.push(post);
        }
      });
      if (
        selectedValuesArray.every(function(item) {
          return thisPostsValues.indexOf(item) !== -1;
        }) == true
      ) {
        filteredPosts.push(post);
      }
    });

    //Check if no results on filter to trigger GTM event
    if (typeof document !== 'undefined') {
      let arrPostItems = document.getElementsByClassName('insights-grid');
      let postItem = arrPostItems.item(0);
      if (postItem != null && postItem.innerHTML === '') {
        if (filteredPosts.length === 0) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'noFilterResults'
          });
        }
      }
    }

    //function to remove duplicates in filteredPosts array
    function removeDuplicates(arr) {
      const final = [];
      arr.map((e, i) => !final.includes(e) && final.push(e));
      return final;
    }

    removeDuplicates(usedTopics);
    removeDuplicates(usedServices);
    filteredPosts = removeDuplicates(filteredPosts);

    let allUsedTopics = [];
    let allUsedServices = [];

    topics.edges.map((topic, index) => {
      for (var i = 0; i < usedTopics.length; i++) {
        if (usedTopics[i] == topics.edges[index].node.wordpress_id) {
          allUsedTopics.push(topic);
        }
      }
    });
    service.edges.map((serv, index) => {
      for (var i = 0; i < usedServices.length; i++) {
        if (usedServices[i] == service.edges[index].node.wordpress_id) {
          allUsedServices.push(serv);
        }
      }
    });
    for (var key in topics.edges) {
      if (topics.edges[key].node.slug == this.state.topicValue) {
        this.setState({ topicValue: topics.edges[key].node.wordpress_id });
      }
      if (topics.edges[key].node.wordpress_id == queryNumber) {
        topicPlaceholder = topics.edges[key].node.name;
      }
    }

    for (var key in service.edges) {
      if (service.edges[key].node.slug == this.state.serviceValue) {
        this.setState({ serviceValue: service.edges[key].node.wordpress_id });
      }
    }
    for (var key in vertical.edges) {
      if (vertical.edges[key].node.slug == this.state.verticalValue) {
        this.setState({ verticalValue: vertical.edges[key].node.wordpress_id });
      }
    }

    //sort all posts by most popular
    let popularPosts = [];
    if (this.state.sortValue == 'most-popular') {
      popularPosts = Object.assign([], posts).sort(function(a, b) {
        return b.node.acf.popularity_num - a.node.acf.popularity_num;
      });
      posts = popularPosts;
    } else {
      posts = props.data.allWordpressPost.edges;
    }

    //sort filtered posts by most popular
    let popularFiltered = [];
    if (this.state.sortValue == 'most-popular' && filteredPosts != 0) {
      popularFiltered = Object.assign([], filteredPosts).sort(function(a, b) {
        return b.node.acf.popularity_num - a.node.acf.popularity_num;
      });
      filteredPosts = popularFiltered;
    } else if (this.state.sortValue == 'most-recent' && filteredPosts != 0) {
      filteredPosts = filteredPosts;
    }

    // Here we are grabbing content for filter dropdowns, the react-select library requires a specific format so we need to rename (name, slug) to be (value, label)
    removeDuplicates(allUsedTopics).forEach((item, index) => {
      topicsList[index] = {
        value: item.node.slug,
        label: item.node.name
      };
    });

    // allUsedServices = allUsedServices.map((service, index) => {
    //   {ReactHtmlParser(allUsedServices[index].node.name)}
    // });

    removeDuplicates(allUsedServices).forEach((item, index) => {
      servicesList[index] = {
        value: item.node.slug,
        label: item.node.name
      };
    });

    props.data.allWordpressWpVertical.edges.forEach((item, index) => {
      verticalsList[index] = {
        value: item.node.slug,
        label: item.node.name
      };
    });

    const hero_headline = props.data.wordpressPage.acf.hero_headline;
    const hero_sub_headline = props.data.wordpressPage.acf.hero_sub_headline;

    const itemPerPage = 6;
    let defaultPosts = [...posts];
    defaultPosts = defaultPosts.splice(
      (this.state.selectedPageId - 1) * itemPerPage,
      itemPerPage
    );
    let conditionalPosts = removeDuplicates(filteredPosts);
    conditionalPosts = conditionalPosts.splice(
      (this.state.selectedPageId - 1) * itemPerPage,
      itemPerPage
    );

    return (
      <StyledBlogContainer className="animate">
        <Helmet>
          {/* General tags */}
          <title>Insights From Our Marketing Experts | Ansira Blog</title>
          <meta
            name="description"
            content="Read marketing insights on topics such as customer experience, channel marketing, loyalty marketing, and marketing technology from Ansira thought leaders."
          />
          <meta name="image" content={DefaultSharingImage} />

          {/* OpenGraph tags */}
          <meta property="og:url" content="/blog" />
          <meta property="og:type" content="website" />

          <meta
            property="og:title"
            content="Insights From Our Marketing Experts | Ansira Blog"
          />
          <meta
            property="og:description"
            content="Read marketing insights on topics such as customer experience, channel marketing, loyalty marketing, and marketing technology from Ansira thought leaders."
          />
          <meta property="og:image" content={DefaultSharingImage} />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="https://twitter.com/ansira" />
          <meta
            name="twitter:title"
            content="Insights From Our Marketing Experts | Ansira Blog"
          />
          <meta
            name="twitter:description"
            content="Read marketing insights on topics such as customer experience, channel marketing, loyalty marketing, and marketing technology from Ansira thought leaders."
          />
          <meta name="twitter:image" content={DefaultSharingImage} />
        </Helmet>
        <Hero headline={hero_headline} sub={hero_sub_headline} />

        <div className="post-area page-container">
          <Filters
            setTopicValue={
              this.state.setTopicValue !== null &&
              Object.getOwnPropertyNames(this.state.setTopicValue).length !== 0
                ? this.state.setTopicValue
                : null
            }
            setServiceValue={
              this.state.setServiceValue !== null &&
              Object.getOwnPropertyNames(this.state.setServiceValue).length !==
                0
                ? this.state.setServiceValue
                : null
            }
            setVerticalValue={
              this.state.setVerticalValue !== null &&
              Object.getOwnPropertyNames(this.state.setVerticalValue).length !==
                0
                ? this.state.setVerticalValue
                : null
            }
            topics={topicsList}
            topicPlaceholder={topicPlaceholder}
            services={servicesList}
            verticals={verticalsList}
            changeSort={this.handleSort}
            changeTopic={this.handleTopic}
            changeService={this.handleService}
            changeVertical={this.handleVertical}
          />
          {filteredPosts.length === 0 && selectedValuesArray.length == 0 ? (
            <>
              <BlogGrid posts={defaultPosts} tags={tags} />
              <TinyPagination
                total={posts.length}
                selectedPageId={this.state.selectedPageId}
                itemPerPage={itemPerPage}
                renderBtnNumber={this.renderBtnNumber}
                maxBtnNumbers={5}
                preKey="<"
                nextKey=">"
              />
            </>
          ) : (
            <div>
              {filteredPosts.length > 0 && conditionalPosts.length > 0 ? (
                <div>
                  <BlogGrid posts={conditionalPosts} tags={tags} />
                  {filteredPosts > conditionalPosts ? (
                    <TinyPagination
                      total={filteredPosts.length}
                      selectedPageId={this.state.selectedPageId}
                      itemPerPage={itemPerPage}
                      renderBtnNumber={this.renderBtnNumber}
                      maxBtnNumbers={4}
                      preKey="<"
                      nextKey=">"
                    />
                  ) : (
                    <TinyPagination
                      total={filteredPosts.length}
                      selectedPageId={this.state.selectedPageId}
                      itemPerPage={itemPerPage}
                      renderBtnNumber={this.renderBtnNumber}
                      maxBtnNumbers={4}
                      preKey="<"
                      nextKey=">"
                    />
                  )}
                </div>
              ) : (
                <div className="no-posts">
                  <p>
                    Sorry there are no blog posts with the selected filters.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        <ContactBanner
          headline={posts_page.acf.contact_headline}
          cta={posts_page.acf.contact_cta}
          url={posts_page.acf.contact_url}
        />
      </StyledBlogContainer>
    );
  }
}

export default BlogPosts;

export const query = graphql`
  query {
    allWordpressPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          wordpress_id
          type
          title
          slug
          content
          date(formatString: "MMMM D, YYYY")
          author {
            name
            slug
            acf {
              author_photo {
                source_url
              }
              author_title
              shortbio
            }
          }
          acf {
            excerpt
            display_date
            featured_post
            popularity_num
            primary_topic
          }
          better_featured_image {
            source_url
          }
          topic
          vertical
          our_solutions
          technology
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
            yoast_wpseo_canonical
          }
        }
      }
    }
    wordpressPage(slug: { eq: "blog" }) {
      content
      slug
      title
      type
      content
      acf {
        hero_headline
        hero_sub_headline
        overview_headline
        contact_headline
        contact_cta
        contact_url
        seo_title
        seo_canonical
        seo_description
      }
    }
    allWordpressCategory {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    allWordpressWpTopic {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    allWordpressWpOurSolutions {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
    allWordpressWpVertical {
      edges {
        node {
          name
          slug
          wordpress_id
        }
      }
    }
  }
`;
